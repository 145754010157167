import { useEffect, useState } from "react";
import * as api from "../api/shoppingCart";
import { CartItem } from "../interfaces/cartItem.interface";
import useStore from "./store.hook";

export default function useShoppingCart() {
    const [storeId] = useStore();
    const [hasInitialized, setHasInitialized] = useState(false);
    const [cart, _setCart] = useState<CartItem[]>(() => {
        const localData = localStorage.getItem("cart");
        return localData === null
            ? []
            : JSON.parse(localData) as CartItem[];
    });

    useEffect(() => {
        if (hasInitialized === false) {
            api.getCartFromServer(storeId)
                .then((response) => {
                    if (response?.cart)
                        innerSetCart(response.cart, false);
                })
                .catch((error) => console.error("Error loading cart from server:", error));
            setHasInitialized(true);
        }
    }, [hasInitialized]);

    function setCart(newCart: CartItem[]) {
        innerSetCart(newCart, true);
    }

    function innerSetCart(newCart: CartItem[], updateRemote: boolean) {
        if (JSON.stringify(cart) !== JSON.stringify(newCart)) {
            const data = newCart.map((x) => ({ quantity: x.quantity, productId: x.id }));

            if (updateRemote === true) {
                api.setCartServer(storeId, data)
                    .then((response) => {
                        if (response.result === "ok") {
                            console.log('setCart', newCart);
                            localStorage.setItem("cart", JSON.stringify(newCart));
                            _setCart(newCart);
                        } else
                            throw new Error(response);
                    })
                    .catch((error) => console.error("Error when saving cart on server:", error));
            } else {
                console.log('setCart', newCart);
                localStorage.setItem("cart", JSON.stringify(newCart));
                _setCart(newCart);
            }
        }
    }

    return [
        cart,
        setCart,
    ] as const;
};
