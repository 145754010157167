import { initBackButton } from "@telegram-apps/sdk-react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useShoppingCart from "../hooks/shoppingCart.hook";
import { MinusIcon } from "../icons/minus";
import { PlusIcon } from "../icons/plus";
import closeButtonIcon from "../img/close-button-rounded.svg";
import { CartItem } from "../interfaces/cartItem.interface";
import { ThumbnailSlider } from "../components/ThumbnailSlider";

function formatNumberWithSpaces(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Product = () => {
  const nav = useNavigate();
  const [totalPrice, setTotalPrice] = useState(0);
  // @ts-ignore
  const [product, setProduct] = useState<ProductType>(JSON.parse(localStorage.getItem("product")));
  const [backButton] = initBackButton();
  const [currentOrder, setCurrentOrder] = useState<CartItem | null>(null /*{ quantity: 0,}*/);
  const [cart, setCart] = useShoppingCart();

  useEffect(() => {
    if (cart.length > 0) {
      const inCart = cart.find((item: any) => item.id === product.id);

      if (inCart && inCart.quantity)
        setCurrentOrder(inCart);
    }

    backButton.show();
    backButton.on('click', goBack);

    return () => {
      backButton.hide();
      backButton.off('click', goBack);
    };
  }, []);

  const goBack = () => nav("/");

  useEffect(() => {
    let price = 0;
    cart.forEach((product: any) => {
      price += product.price * product.quantity * product.inBox;
    });
    setTotalPrice(price);
  }, [cart]);

  return (<>
    <div className="container-food">
      <div className="foodPage">
        <div className="data-food">

          <ThumbnailSlider thumbnail={product.thumbnail} photos={product.photos} />

          <div className="title-food">
            <span className="text-title-food">{product.name}</span>
          </div>
          <div className="description-food">
            {product.keyWords.length === 0 || product.keyWords[0] === ""
              ? null
              : (<div>
                <span>Ключевые слова:{" "}</span>
                {product.keyWords.map((str: any, index: number) => (
                  <span key={index}>
                    {str}
                    {index !== product.keyWords.length - 1 && ", "}
                  </span>
                ))}
              </div>)}
            {product.description && (<>
              <div className="product-decription">Description:<br />
                <p>{product.description}</p>
              </div>
            </>)}
            {product.video &&
              (<>
                <b>Product review</b>
                <iframe
                  style={{ marginTop: 12, }} width="100%"
                  height="315"
                  src={"https://www.youtube.com/embed/" + product.video.replace("https://www.youtube.com/watch?v=", "").split("&")[0]}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </>)}
          </div>
        </div>
      </div>
      <div className="product-count-wrap">
        <div className="pcw-btn-group">
          <div className="price-product-card">
            <span className="js-product-price">{formatNumberWithSpaces(product.price)}&nbsp;₽</span>
          </div>
          {currentOrder && currentOrder.quantity > 0 ? (
            <div className="pcw-count-wrap">
              <div
                className="pcw-minus"
                onClick={() => {
                  if (currentOrder.quantity <= product.inStock) {
                    if (parseFloat((currentOrder.quantity - 1).toFixed(2)) > 0) {
                      const newCart = cart.map((item: any) => {
                        if (item.id === product.id)
                          item.quantity -= 1;
                        return item;
                      });
                      setCart(newCart);
                      setCurrentOrder({ ...currentOrder, quantity: parseFloat((currentOrder.quantity - 1).toFixed(2)), });
                    } else {
                      const newCart = cart.filter((item) => item.id !== product.id);
                      setCart(newCart);
                      setCurrentOrder(null);
                    }
                  } else {
                    // console.log("del");
                    const newCart = cart.filter((item) => item.id !== product.id);
                    setCart(newCart);
                    setCurrentOrder(null);
                  }
                }}
              >
                <MinusIcon />
              </div>
              <div className="pcw-count">{currentOrder && currentOrder.quantity}</div>
              <div
                className="pcw-plus"
                onClick={() => {
                  if (currentOrder && currentOrder.quantity < product.inStock) {
                    const newCart = cart.map((item) => {
                      if (item.id === product.id)
                        item.quantity += 1;

                      return item;
                    });
                    setCart(newCart);
                    setCurrentOrder({ ...currentOrder, quantity: currentOrder.quantity + 1, } as CartItem);
                  }
                }}
              >
                <PlusIcon />
              </div>
            </div>
          ) : product.inStock > 0 ? (
            <div
              onClick={() => {
                if (currentOrder && currentOrder.quantity > 0) {
                  nav("/cart");
                } else {
                  const newCart = [...cart, { ...product, quantity: 1, },];
                  setCart(newCart);
                  setCurrentOrder({ ...currentOrder, quantity: 1, } as CartItem);
                }
              }}
              className="gto-btn">
              <a href="#" className="js-add-cart">Add to cart</a>
            </div>
          ) : (
            <div className="gto-btn">
              <a href="#" className="js-add-cart">Out of stock</a>
            </div>
          )}
          {currentOrder && currentOrder.quantity > 0 ? (
            product.inStock > 0 ? (
              <div className="gto-btn" onClick={() => nav("/cart")}>
                <a href="#" className="js-add-cart">Add to cart</a>
              </div>
            ) : (
              <div className="gto-btn">
                <a href="#" className="js-add-cart">Out of stock</a>
              </div>
            )
          ) : null}
        </div>
      </div>
    </div>
  </>
  );
};

export default Product;
