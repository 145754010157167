import { initMainButton } from "@telegram-apps/sdk-react";
import { useState, useRef, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getProducts } from "../api";
import useShoppingCart from "../hooks/shoppingCart.hook";
import menuIcon from "../img/menu.svg";
import logo from "../img/spruton-octo-white.png";
import arrowIcon from "../img/arrow-right.svg";
import cartIcon from "../img/shopping-cart.svg";
import phoneIcon from "../img/phone-call.svg";
import searchIcon from "../img/search.svg";
import caseIcon from "../img/briefcase.svg";
import useStore from "../hooks/store.hook";
import { CartItem } from "../interfaces/cartItem.interface";
import { Category } from "../interfaces/category.interface";
import { ProductInfo } from "../interfaces/product.interface";
import { ProductPreview } from "../components/ProductPreview";

export function formatNumberWithSpaces(num: any) {
  return parseInt(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

type CategoryWithIndex = Category & { originalIndex: number };

async function fetchProductsFromServer(storeId: number): Promise<Category[]> {
  const categories: Category[] = (await getProducts(storeId))
    .reduce((acc: Category[], product: ProductInfo) => {
      let category = acc.find(c => c.categoryName === product.category);

      if (!category) {
        category = { categoryName: product.category, products: [] };
        acc.push(category);
      }

      category.products.push(product);

      return acc;
    }, [] as Category[]);

  return categories;
  // setCategories(categories);
}

export function Catalog() {
  const nav = useNavigate();

  const [storeId] = useStore();
  const [categories, setCategories] = useState<Category[]>([]);
  const [activeCategory, setActiveCategory] = useState(0);
  const [cart, setCart] = useShoppingCart();
  const [mainButton] = initMainButton();
  const [totalPrice, setTotalPrice] = useState(0);
  const [showMainButton, setShowMainButton] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [dropdownOpened2, setDropdownOpened2] = useState(false);
  const [dropdownOpened3, setDropdownOpened3] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [showTopButton, setShowTopButton] = useState<boolean>(false);

  const menuContainersRefs = useRef<HTMLDivElement[]>([]);
  const navigationRef = useRef(null);

  useEffect(() => {
    fetchProductsFromServer(storeId)
      .then((categories: Category[]) => setCategories(categories));
  }, []);

  useEffect(() => {
    const i = setInterval(() => {
      if (window.pageYOffset > 100) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    }, 100);

    return () => clearInterval(i);
  }, []);

  useEffect(() => {
    if (showMainButton === true) {
      mainButton.setText('Оформить заказ');
      mainButton.enable()
      mainButton.show();
      mainButton.on('click', goToCart);
    } else
      mainButton.hide();

    return () => {
      mainButton.hide();
      mainButton.off('click', goToCart);
    };
  }, []);

  useEffect(() => {
    let price = 0;
    cart.forEach((product) => {
      price += product.price * product.quantity;
    });
    setTotalPrice(price);

    if (price > 0) {
      setShowMainButton(true);
    } else {
      setShowMainButton(false);
    }
  }, [cart]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const navigationRect = navigationRef.current.getBoundingClientRect();
  //
  //     for (let i = 0; i < menuContainersRefs.current.length; i++) {
  //       const containerRect =
  //         menuContainersRefs.current[i].getBoundingClientRect();
  //
  //       if (containerRect.top >= navigationRect.top) {
  //         setActiveCategory(i);
  //         break;
  //       }
  //     }
  //   };
  //
  //   window.addEventListener("scroll", handleScroll);
  //
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const handleCategoryClick = (index: any) => {
    setActiveCategory(index);
    menuContainersRefs.current[index].scrollIntoView({ behavior: "smooth" });
  };

  const goToCart = () => nav("/cart");

  return (
    <>
      {dropdownOpened3 ? (
        <div className="dropdown-wrap" onClick={() => setDropdownOpened3(false)}>
          <div className="dropdown2">
            <p>
              <b>График работы:</b> 06:00 - 19:00
              <b>Склад:</b> 07:00 - 20:00
            </p>
          </div>
        </div>
      ) : null}
      {dropdownOpened2 ? (
        <div className="dropdown-wrap" onClick={() => setDropdownOpened2(false)}>
          <div className="dropdown2">
            <p>
              По всем вопросам обращаться по телефону:{" "}
              <a href="tel:+79250685113">+79250685113</a>
            </p>
          </div>
        </div>
      ) : null}
      <div
        onClick={() => {
          if (dropdownOpened) setDropdownOpened(false);
          if (dropdownOpened2) setDropdownOpened2(false);
          if (dropdownOpened3) setDropdownOpened3(false);
        }}
        className="container">
        <div className="menu">
          <div className="logo" onClick={() => nav("/")} >
            <img src={logo} className="logo" />
          </div>

          <div className="search">
            <input placeholder="Поиск..." value={searchInput} onChange={(e) => { setSearchInput(e.target.value); }} />
            <div className="icon" onClick={() => { nav(`/search?q=${searchInput}`); }}>
              <img src={searchIcon} />
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center", }}          >
            <div className="bottomBar">
              <div className={`icon ${dropdownOpened2 && "activeIcon"}`}>
                <img src={phoneIcon} onClick={() => setDropdownOpened2(!dropdownOpened2)} />
              </div>
              <div className={`icon ${dropdownOpened3 && "activeIcon"}`}>
                <img src={caseIcon} onClick={() => setDropdownOpened3(!dropdownOpened3)} />
              </div>
              <div className="icon">
                <img src={cartIcon} onClick={() => nav("/cart")} />
              </div>
            </div>

            <div className={`menuIcon ${dropdownOpened && "activeIcon"}`}>
              <img src={menuIcon} onClick={() => setDropdownOpened(!dropdownOpened)} />
            </div>
          </div>
        </div>
        {dropdownOpened ? (
          <div className="dropdown">
            <div className="item" onClick={() => nav("/orders")}>
              Orders history <img src={arrowIcon} />
            </div>
            {categories
              .map((category, index) => ({ originalIndex: index, category }))
              .sort((a: any, b: any) => {
                if (a.category.categoryName === "Новинки") return -1;
                if (b.category.categoryName === "Новинки") return 1;

                return a.category.categoryName.localeCompare(b.category.categoryName);
              })
              .map((sortedCategory: any, sortedIndex) => {
                const { category, originalIndex } = sortedCategory;
                const filteredProducts = category.products.filter(
                  (product: any) => product.price && parseInt(product.price) !== 0
                );
                if (filteredProducts.length === 0) return null;
                return (
                  <div className="item" onClick={() => nav("/category/" + originalIndex)} key={sortedIndex}                  >
                    {category.categoryName}
                    <img src={arrowIcon} />
                  </div>
                );
              })}
            <br />
          </div>
        ) : null}

        {/* <div className="navigation" ref={navigationRef}>
          {products.map((category, index) => (
            <div
              key={index}
              className={`navItem ${activeCategory === index && "active"}`}
              id={`category${index}`}
              onClick={() => handleCategoryClick(index)}
            >
              {category.categoryName}
            </div>
          ))}
        </div> */}

        {/* <div className="banner" onClick={() => handleCategoryClick(categories.length - 1)}        >
          <img src="/banner.jpg" />
        </div> */}

        <CategoryList categories={categories} menuContainersRefs={menuContainersRefs} />

        {totalPrice > 0 && (<div className="go-to-order-wrap " onClick={() => nav("/cart")}>Заказ на {formatNumberWithSpaces(totalPrice)} ₽</div>)}

        {showTopButton === true && (
          <div
            style={{ position: "fixed", bottom: 20, right: 20, width: 50, height: 50, borderRadius: 25, backgroundColor: "#2a2a2a", justifyContent: "center", alignItems: "center", cursor: "pointer", zIndex: 1000, display: "flex", }}
            onClick={() => { window.scrollTo(0, 0); }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"            >
              <path fillRule="evenodd" clipRule="evenodd" d="M5.23191 10.6401C5.58591 11.0641 6.21591 11.1221 6.63991 10.7681L10.9999 7.13512V19.0001C10.9999 19.5521 11.4479 20.0001 11.9999 20.0001C12.5519 20.0001 12.9999 19.5521 12.9999 19.0001V7.13512L17.3599 10.7681C17.7849 11.1221 18.4149 11.0641 18.7679 10.6401C19.1219 10.2161 19.0649 9.58512 18.6399 9.23212L12.6399 4.23212C12.5929 4.19212 12.5369 4.17312 12.4849 4.14312C12.4439 4.11912 12.4079 4.09112 12.3629 4.07312C12.2459 4.02712 12.1239 4.00012 11.9999 4.00012C11.8759 4.00012 11.7539 4.02712 11.6369 4.07312C11.5919 4.09112 11.5559 4.11912 11.5149 4.14312C11.4629 4.17312 11.4069 4.19212 11.3599 4.23212L5.35991 9.23212C4.93591 9.58512 4.87791 10.2161 5.23191 10.6401Z" fill="#fff" />
              <mask id="mask0_7_4676" style={{ "maskType": "luminance" }} maskUnits="userSpaceOnUse" x="5" y="4" width="15" height="17"              >
                <path fillRule="evenodd" clipRule="evenodd" d="M5.23191 10.6401C5.58591 11.0641 6.21591 11.1221 6.63991 10.7681L10.9999 7.13512V19.0001C10.9999 19.5521 11.4479 20.0001 11.9999 20.0001C12.5519 20.0001 12.9999 19.5521 12.9999 19.0001V7.13512L17.3599 10.7681C17.7849 11.1221 18.4149 11.0641 18.7679 10.6401C19.1219 10.2161 19.0649 9.58512 18.6399 9.23212L12.6399 4.23212C12.5929 4.19212 12.5369 4.17312 12.4849 4.14312C12.4439 4.11912 12.4079 4.09112 12.3629 4.07312C12.2459 4.02712 12.1239 4.00012 11.9999 4.00012C11.8759 4.00012 11.7539 4.02712 11.6369 4.07312C11.5919 4.09112 11.5559 4.11912 11.5149 4.14312C11.4629 4.17312 11.4069 4.19212 11.3599 4.23212L5.35991 9.23212C4.93591 9.58512 4.87791 10.2161 5.23191 10.6401Z" fill="#fff" />
              </mask>
              <g mask="url(#mask0_7_4676)">
                <rect width="24" height="24" fill="#fff" />
              </g>
            </svg>
          </div>
        )}
      </div>
      <footer>
        <div className="footer">
          <img src={logo} className="logo" />

          <div className="phone">
            <a href={"tel:+79250685113"}>+79250685113</a>
          </div>
        </div>
        <p>Работает на базе Spruton</p>
      </footer>
      <Toaster />
    </>
  );
};

export function CategoryList(props: any) {
  return props.categories
    .map((categoryy: Category, index: number) => ({ ...categoryy, originalIndex: index } as CategoryWithIndex))
    .sort((a: CategoryWithIndex, b: CategoryWithIndex) => a.categoryName.localeCompare(b.categoryName))
    .map((category: CategoryWithIndex) => {
      const filteredProducts = category
        .products
        .filter((product: ProductInfo) => product.price && product.price !== 0);

      if (filteredProducts.length === 0) return null;

      return <CategoryListItem key={category.originalIndex} category={category} />
    });
}

export function CategoryListItem(props: any) {
  const nav = useNavigate();
  const [cart, setCart] = useShoppingCart();
  const { category }: { category: CategoryWithIndex } = props;

  return (
    <>
      <div
        className="title" id={`category${category.originalIndex}`} onClick={() => nav("/category/" + category.originalIndex)} >
        {category.categoryName}
        <img src={arrowIcon} />
      </div>
      <div
        className="menuContainer"
      // ref={(ref: any) => (props.menuContainersRefs.current[category.originalIndex] = ref)}
      >
        {category
          .products
          .slice(0, 18)
          .map((product: ProductInfo) => {
            const inCart: CartItem | undefined = cart.find((_product: ProductInfo) => _product.id == product.id);

            if (!product.price || (product.price) === 0) return;
            if (!product.inStock || (product.inStock) === 0)
              return;

            return <ProductPreview key={product.id} product={product} inCart={inCart} setCart={setCart} cart={cart} />
          })}
      </div>
    </>
  );
}

export default Catalog;
