import { initMainButton, initBackButton } from "@telegram-apps/sdk-react";
import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getTelegramUser, newOrder } from "../api";
import useShoppingCart from "../hooks/shoppingCart.hook";
import useStore from "../hooks/store.hook";
import { Order as TOrder } from "../interfaces/order.interface";
import { DeliveryIcon } from "../icons/delivery";
import { PaymentIcon } from "../icons/payment";
import { ShevronRightDarkIcon } from "../icons/shevronRightDark";
import { CartItem } from "../interfaces/cartItem.interface";
import arrowLeft from "../img/arrow-left.svg";

const Order = () => {
  const nav = useNavigate();
  const buyer = getTelegramUser();
  const [cart, setCart] = useShoppingCart();
  const [storeId] = useStore();
  const [deliveryData, setDeliveryData] = useState<number>(2);
  const [paymentData, setPaymentData] = useState<number>(3);
  const [mainButton] = initMainButton();
  const [backButton] = initBackButton();
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [data, setData] = useState<TOrder>({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    comment: "",
    buyerId: buyer.id,
    storeId,
  });
  const [canOrder, setCanOrder] = useState(true);

  function changePaymentData(evt: any) {
    // setPaymentData(e.target.getAttribute("x-data-value")
  }

  function paymentTypeToText(type: number | string): string {
    switch (Number(type)) {
      case 3: return "Cash";
      case 4: return "Cart";
      case 5: return "Bank account";
      case 6:
      default: return "Pay with Telegram";
    }
  }

  useEffect(() => {
    backButton.show();
    backButton.on('click', goBack);

    return () => {
      backButton.hide();
      backButton.off('click', goBack);

      mainButton.hide();
    };
  }, []);

  const goBack = () => nav("/cart");

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));

    let price = 0;
    cart.forEach((product) => {
      price += product.price * product.quantity;
    });
    setTotalPrice(price);
  }, [cart]);

  useEffect(() => {
    if (data.firstName && data.lastName && data.phone && data.address) {
      if (deliveryData === 2 && !data.address) {
        mainButton.hide();
        alert('Delivery address required');
        return () => { };
      }

      mainButton.setText("Create order");
      mainButton.on('click', createOrder);
      mainButton.enable();
      mainButton.show();
    } else {
      mainButton.hide();
    }
    return () => mainButton.off('click', createOrder)
  }, [data, deliveryData]);

  const createOrder = async () => {
    mainButton.off('click', createOrder);

    if (!canOrder) return;

    setCanOrder(false);

    const order = {
      ...data,
      delivery: Number(deliveryData),
      payBy: Number(paymentData),
      products: cart.map((product: CartItem) => ({
        id: Number(product.id),
        name: product.name,
        quantity: Number(product.quantity),
        price: Number(product.price)
      })),
    };

    const result = await newOrder(order);

    if (result.message === 'order_created') {
      localStorage.removeItem("cart");

      setCart([]);
      mainButton.hide();
      toast.success("The order has been placed, our manager will contact you shortly");

      setTimeout(() => {
        setData({ firstName: "", lastName: "", phone: "", email: "", address: "", comment: "", buyerId: buyer.id, storeId, } as TOrder);
        nav("/");
      }, 3000);
    } else {
      toast.error("Error creating order. Please check your entered information or try again later.");
    }
  };

  return (
    <>
      <div className="container-order-data">
        <div className="order-form">
          <img src={arrowLeft} style={{ marginBottom: 12, }} onClick={() => nav("/cart")} />
          <div className="order-form-select-group">
            <div className="order-choise-btn-label">
              <DeliveryIcon />
              <select className="choise-btn" value={deliveryData} onChange={(e) => setDeliveryData(Number(e.target.value))}>
                <option value="1" disabled>Pickup</option>
                <option value="2">Courier</option>
              </select>
              <ShevronRightDarkIcon />
            </div>
            <div className="order-choise-btn-label" x-data-value={paymentData} onChange={(e) => changePaymentData(e)}>
              <PaymentIcon />
              <select className="choise-btn">
                <option value="3" >{paymentTypeToText(3)}</option>
                <option value="4" disabled>{paymentTypeToText(4)}</option>
                <option value="5" disabled>{paymentTypeToText(5)}</option>
                <option value="6" disabled>{paymentTypeToText(6)}</option>
              </select>
              <ShevronRightDarkIcon />
            </div>
          </div>

          {/* {deliveryData == "1" && (
            <div className="delivery-description">
              Адрес самовывоза: Ул. Тестовская, д. 1
            </div>
          )} */}

        <p className="order-data-page-title">Recipient</p>

          <div className="form-group-section">
            <div className="form-group">
              <input type="text" className="formInput" placeholder="First name" value={data.firstName} onChange={(e) => setData({ ...data, firstName: e.target.value, })} />
            </div>
            <div className="form-group">
              <input type="text" className="formInput" placeholder="Last name" value={data.lastName} onChange={(e) => setData({ ...data, lastName: e.target.value, })} />
            </div>
            <div className="form-group">
              <input type="text" className="formInput" placeholder="Phone" value={data.phone} onChange={(e) => setData({ ...data, phone: e.target.value, })} />
            </div>
            <div className="form-group">
              <input type="text" className="formInput" placeholder="E-mail (optional)" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value, })} />
            </div>
            {deliveryData === 2 && (
              <div className="form-group">
                <input type="text" className="formInput" placeholder="Address" value={data.address} onChange={(e) => setData({ ...data, address: e.target.value, })} />
              </div>
            )}
            <div className="form-group">
              <input type="text" className="formInput" placeholder="Comment (optional)" onChange={(e) => setData({ ...data, comment: e.target.value, })} value={data.comment} />
            </div>
          </div>

          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", }}>
            <p style={{ color: "rgba(255, 255, 255, 0.5)", }}>Works on Spruton</p>
          </div>

          <div className="delivery-description text-red"></div>
        </div>
        <div className="go-to-order-wrap">Total price ${totalPrice}</div>
      </div>

      <Toaster />
    </>
  );
};

export default Order;
