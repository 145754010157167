import { initBackButton } from "@telegram-apps/sdk-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


export function InDevelopment() {
    const nav = useNavigate();
    const [backButton] = initBackButton();
    const goBack = () => nav("/");

    useEffect(() => {
        backButton.show();
        backButton.on('click', goBack);

        return () => {
            backButton.hide();
            backButton.off('click', goBack);
        };
    }, []);

    return (<>
        <h1 style={{ color: 'white', textAlign: 'center', padding: '1rem' }}>Функционал находится в разработке</h1>
    </>);
};