import sha1 from 'crypto-js/sha1';

export function calcHash(filename: string): string {
    return sha1(filename).toString();
}

export function getAttachementUrl(attachment: any | string): string {
    if (attachment?.url !== undefined)
        return attachment.url;

    if (typeof (attachment) === 'string' && attachment.includes('/attachments/'))
        return attachment

    const urlPath = '//images.spruton.app/spruton/attachments/';
    const filename = (!!attachment.filename) ? attachment.filename : attachment;
    const file_sha1 = sha1(filename);
    const filename_array = filename.split('_');
    const filetime = parseInt(filename_array[0], 10);

    const filepath =
        new Date(filetime * 1000).getFullYear()
        + `/`
        + ('0' + (new Date(filetime * 1000).getMonth() + 1)).slice(-2)
        + `/`
        + ('0' + new Date(filetime * 1000).getDate()).slice(-2)
        + `/`
        + file_sha1;

    return urlPath + filepath;
}
