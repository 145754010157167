export const PaymentIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <rect width="30" height="30" rx="7.33333" fill="#1FB73A" />
        <g clip-path="url(#clip0_19_453)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.77982 10.8643C7.89944 11.2604 7.33325 12.1361 7.33325 13.1015V18.761C7.33325 20.5412 9.16987 21.7288 10.7933 20.9982L19.5999 13.1015V12.3334V9.78951C19.5999 8.00923 17.7633 6.8217 16.1398 7.55227L8.77982 10.8643ZM17.3333 10.3374C17.3333 9.86064 16.8473 9.53798 16.4079 9.723L7.92985 13.2927C7.25047 13.5788 7.4692 14.593 8.20609 14.5736L16.6841 14.3505C17.0454 14.341 17.3333 14.0454 17.3333 13.684V10.3374Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99992 12C8.52716 12 7.33325 13.1939 7.33325 14.6667V19.8933C7.33325 21.3661 8.52716 22.56 9.99992 22.56H19.9999C21.4727 22.56 22.6666 21.3661 22.6666 19.8933V14.6667C22.6666 13.1939 21.4727 12 19.9999 12H9.99992ZM19.2666 16C18.567 16 17.9999 16.5671 17.9999 17.2667C17.9999 17.9662 18.567 18.5333 19.2666 18.5333C19.9661 18.5333 20.5333 17.9662 20.5333 17.2667C20.5333 16.5671 19.9661 16 19.2666 16Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_19_453">
                <rect width="15.3333" height="15.3333" fill="white" transform="translate(7.33325 7.33337)" />
            </clipPath>
        </defs>
    </svg>
);