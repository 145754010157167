import { User } from "@telegram-apps/sdk-react";
import { TelegramUser } from "../interfaces/telegramUser.interface";

export default class TelegramUserModel implements TelegramUser {
    public readonly id: number;
    public readonly firstName: string;
    public readonly lastName?: string;
    public readonly username?: string;
    public readonly isBot?: boolean;
    public readonly isPremium?: boolean;
    public readonly languageCode?: string;
    public readonly photoUrl?: string;
    public _created?: boolean;

    private constructor(user: TelegramUser) {
        this.id = user.id;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.username = user.username;
        this.isBot = user.isBot;
        this.isPremium = user.isPremium;
        this.languageCode = user.languageCode;
        this.photoUrl = user.photoUrl;
        this._created = user._created ?? false;
    }

    public static fromLaunchParams(user: User): TelegramUserModel {
        return new TelegramUserModel(user);
    }
}
