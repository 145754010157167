import Stories from 'react-insta-stories';
import { ImageInfo } from '../interfaces/ImageInfo.interface';
import { Story } from 'react-insta-stories/dist/interfaces';
import { getAttachementUrl } from '../utils';

export interface ThumbnailSliderProps {
  thumbnail?: any;
  photos?: any[];
}

export function ThumbnailSlider(props: ThumbnailSliderProps) {
  const images: Story[] = [
    props.thumbnail?.url ?? props.thumbnail,
    ...props.photos ?? []
  ]
    .filter((x: any) => (x.length && x.length > 0) || (x.url && x.url?.length > 0))
    .map((x: ImageInfo): Story => ({
      url: getAttachementUrl(x)
    }));

  return (<>
    <div className="product-image-container">
      {images.length === 0
        ? (<div className="product-image-empty">No product image</div>)
        : (<>
          <div className="product-image">
            <Stories
              isPaused={true}
              loop={true}
              stories={images}
              defaultInterval={11500}
            />
          </div>
        </>)}
    </div>
  </>);
}
