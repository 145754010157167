import { User as TmaUser,retrieveLaunchParams } from "@telegram-apps/sdk-react";
import TelegramUserModel from "../models/telegramUser.model";

const apiEndpoint = 'https://api.spruton.app';

const getProducts = async (storeId: number): Promise<any[]> => {
  const res = await fetch(apiEndpoint + `/products/` + storeId, {
    // headers: {authorization: `TODO`},
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));

  return res?.data ?? [];
};

export function getTelegramUser(): TelegramUserModel {
  const storedUser = localStorage.getItem("user");
  let user: TelegramUserModel;

  if (storedUser === null) {
    const tmaUser = retrieveLaunchParams()?.initData?.user as TmaUser;

    if (!!tmaUser.id)
      localStorage.setItem("user", JSON.stringify(tmaUser));

    user = TelegramUserModel.fromLaunchParams(tmaUser);
  } else {
    user = TelegramUserModel.fromLaunchParams(JSON.parse(storedUser) as TmaUser);
  }

  if (user._created !== true) {
    tryTelegramUser({ ...user, _created: undefined })
      .then((response) => {
        if (response.message === 'user_created' || response.message === 'buyer_already_exists')
          localStorage.setItem("user", JSON.stringify({ ...user, _created: true }));
        else
          throw new Error(response);
      });
  }

  return user;
};

async function tryTelegramUser(user: TelegramUserModel): Promise<any> {
  const res = await fetch(apiEndpoint + `/buyers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((res) => res.json());

  return res;
}

const newOrder = async (data: any) => {
  const req = await fetch("https://api.spruton.app/order", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data, }),
  });

  const res = await req.json();

  return res;
};

export { getProducts, newOrder };