import { useState } from "react";

export default function useStore() {
    const [storeId, _] = useState<number>(() => {
        const searchParams = new URL(window.location.href).searchParams;
        const idFromUrl = searchParams.has('store');

        if (idFromUrl) {
            const id: number = parseInt(searchParams.get('store') ?? "", 10);

            if (id > 0) {
                localStorage.setItem("store", id.toString());
                return id;
            } else
                throw new Error(`Неизвестный магазин c идентификатором ${id}`);
        } else if (localStorage.getItem("store") !== null) {
            const localData = localStorage.getItem("store") ?? "";
            const id: number = parseInt(localData ?? "", 10);

            if (id > 0)
                return id;
            else
                throw new Error(`Неизвестный магазин c идентификатором ${id}`);
        }

        throw new Error(`Не удалось полусить идентификатор магазина`);
    });

    return [storeId] as const;
}