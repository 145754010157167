import React from 'react';

export const CloseIcon = (props: any) => {
    return (
        <svg
            className={props.className}
            onClick={props.onClick}
            width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill="transparent" />
            <path d="M7 17L16.8995 7.10051" stroke="red" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 7.00001L16.8995 16.8995" stroke="red" strokeLinecap="round" strokeLinejoin="round" />
        </svg>);
};