import { getTelegramUser } from ".";

const apiEndpoint = 'https://api.spruton.app/cart';

export async function setCartServer(storeId: number, cart: any[]): Promise<any> {
    const buyer = getTelegramUser();

    try {
        const data = { cart, buyerId: buyer.id, storeId };
        const response = await fetch(apiEndpoint + `/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify(data),
        });

        if (!response.ok)
            throw new Error('Network response was not ok');

        const { updatedCart, result } = await response.json();

        return { updatedCart, result };
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
}

export async function getCartFromServer(storeId: number): Promise<any> {
    const buyer = getTelegramUser();

    try {
        const response = await fetch(apiEndpoint + `/` + storeId + `/` + buyer.id, {
            method: 'GET',
        });

        if (!response.ok)
            throw new Error('Network response was not ok');

        const cart = await response.json();

        return cart;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
}