import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Catalog from "./views/Catalog";
import Cart from "./views/Cart";
import Order from "./views/Order";
import Product from "./views/Product";
import Category from "./views/Category";
import Contact from "./views/Contact";
import Terms from "./views/Terms";
import Orders from "./views/Orders";
import OrderInfo from "./views/OrderInfo";
import Search from "./views/Search";

import { Toaster } from "react-hot-toast";
import { InDevelopment } from "./views/InDevelopment";

const App = () => {
  return (
    <BrowserRouter>
      <Toaster />
      <Routes>
        <Route path="/" element={<Catalog />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/order" element={<Order />} />
        <Route path="/product/:id" element={<Product />} />
        {/* <Route path="/category/:id" element={<Category />} /> */}
        {/* <Route path="/orderInfo/:id" element={<OrderInfo />} /> */}
        {/* <Route path="/contact" element={<Contact />} /> */}
        {/* <Route path="/terms" element={<Terms />} /> */}
        {/* <Route path="/orders" element={<Orders />} /> */}
        {/* <Route path="/search" element={<Search />} /> */}
        <Route path="/category/:id" element={<InDevelopment />} />
        <Route path="/orderInfo/:id" element={<InDevelopment />} />
        <Route path="/contact" element={<InDevelopment />} />
        <Route path="/terms" element={<InDevelopment />} />
        <Route path="/orders" element={<InDevelopment />} />
        <Route path="/search" element={<InDevelopment />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;