import { initMainButton } from "@telegram-apps/sdk-react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useShoppingCart from "../hooks/shoppingCart.hook";
import { ArrowLeft } from "../icons/arrowLeft";
import { CloseIcon } from "../icons/close";
import { MinusIcon } from "../icons/minus";
import { PlusIcon } from "../icons/plus";
import { CartItem } from "../interfaces/cartItem.interface";
import { getAttachementUrl } from "../utils";

function formatNumberWithSpaces(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Cart = () => {
  const nav = useNavigate();
  const [cart, setCart] = useShoppingCart();
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const goToOrder = () => nav("/order");
  const [mainButton] = initMainButton();

  const deleteCart = () => {
    setCart([]);
    nav("/");
  };

  useEffect(() => {
    if (totalPrice > 0) {
      mainButton.setText('Correct, next');
      mainButton.enable()
      mainButton.show();
      mainButton.on('click', goToOrder);

      return () => {
        mainButton.hide();
        mainButton.off('click', goToOrder);
      };
    }
  }, [totalPrice]);

  useEffect(() => {
    let price = 0;
    cart.forEach((product: CartItem) => {
      price += product.price * product.quantity;
    });
    setTotalPrice(price);
  }, [cart]);

  function removeItemFromCart(product: CartItem) {
    setCart(cart.filter((i) => i.id !== product.id));
  }

  return (
    <div className="container-order">
      <div className="page-title-order">
        <ArrowLeft onClick={() => nav("/")} />
        <span>Order</span>
        <button className="cart-empty" onClick={() => deleteCart()}></button>
      </div>
      <div className="cart1">
        {cart.map((product: CartItem, index: number) => (
          <div className="cart-item-row" key={product.id} style={{ position: "relative" }}>
            <div className="cart-item-picture"                  >
              <img src={getAttachementUrl(product.thumbnail) ?? 'empty-thumbnail-102x102.png'} alt="picture" onClick={() => {
                if (product.inStock <= 0) return;
                localStorage.setItem("product", JSON.stringify({ ...product, }));
                nav("/product/" + product.id);
              }} />
              <CloseIcon className="close_ic" onClick={() => removeItemFromCart(product)} />
            </div>
            <div className="cart-item-data">
              <div
                className="cart-item-label"
                onClick={() => {
                  if (product.inStock <= 0) return;
                  localStorage.setItem("product", JSON.stringify({ ...product, }));
                  nav("/product/" + product.id);
                }}
              >
                <span>{product.name}{!!product.SKU
                  ? (<><br /><i>SKU:</i>&nbsp;{product.SKU}</>)
                  : ""}</span>
                {/* <div className="cart-item-caption">PM3: {product.inBox} шт<br /></div> */}
                <span>{formatNumberWithSpaces(product.price * product.quantity)}&nbsp;₽</span>
              </div>
              {Number(product.inStock) > 0 ? (
                <div className="cart-item-counter ">
                  <div
                    className="cic-minus"
                    onClick={() => {
                      const minusAmount = 1;

                      if (parseFloat((product.quantity - minusAmount).toFixed(2)) > 0) {
                        setCart(cart.map((_product: CartItem) => {
                          if (_product.id === product.id)
                            return {
                              ..._product,
                              quantity: Math.max(Number(_product.quantity) - minusAmount, 1)
                            };
                          return _product;
                        }));
                      } else {
                        setCart(cart.filter((_product: CartItem) => _product.id != product.id));
                      }
                    }}
                  >
                    <MinusIcon />
                  </div>
                  <div className="cic-count">{product.quantity}</div>
                  <div
                    className="cic-plus"
                    onClick={() => {
                      if (product.quantity < product.inStock) {
                        const incrementAmount = 1;

                        setCart(
                          cart.map((_product) => {
                            if (_product.id === product.id)
                              return {
                                ..._product,
                                quantity: Math.min(Number(_product.quantity) + incrementAmount, product.inStock)
                              };
                            return _product;
                          })
                        );
                      }
                    }}
                  >
                    <PlusIcon />
                  </div>
                </div>
              ) : (
                <div className="cart-item-counter notqqq">
                  <div>Out of stock</div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="button-block">
        {totalPrice == 0 ? (
          <center>
            <p>Cart is empty</p>
          </center>
        ) : null}
        {totalPrice > 0 ? (
          <div className="go-to-order-wrap">Total price {formatNumberWithSpaces(totalPrice)}&nbsp;₽</div>
        ) : null}
      </div>
    </div >
  );
};

export default Cart;
