import { MinusIcon } from "../icons/minus";
import { PlusIcon } from "../icons/plus";
import { ProductInfo } from "../interfaces/product.interface";
import { CartItem } from "../interfaces/cartItem.interface";

export function CatalogCounter(props: any) {
  const { product, inCart, setCart, cart }: { product: ProductInfo; inCart: CartItem; setCart: Function; cart: any[]; } = props;

  return (
    <div className="add catalog_counter">
      <div className="cic-minus"
        onClick={() => {
          const minusAmount = 1;
   
          if (parseFloat((inCart.quantity - minusAmount).toFixed(2)) > 0) {
            setCart(
              cart.map((_product: any) => {
                if (_product.id === product.id) {
                  return {
                    ..._product,
                    quantity: parseFloat((_product.quantity - minusAmount).toFixed(2)),
                  } as CartItem;
                }
                return _product;
              })
            );
          } else {
            setCart(cart.filter((_product: any) => _product.id != product.id));
          }
        }}>
        <MinusIcon />
      </div>
      <div className="amount">{inCart.quantity}</div>
      <div
        className="cic-plus"
        onClick={() => {
          if (inCart.quantity < product.inStock) {
            const incrementAmount = 1;

            setCart(
              cart.map((_product: any) => {
                if (_product.id === product.id)
                  return { ..._product, quantity: parseFloat((_product.quantity + incrementAmount).toFixed(2)), };
                return _product;
              })
            );
          }
        }}
      >
        <PlusIcon />
      </div>
    </div>
  );
}
