import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SDKProvider } from '@telegram-apps/sdk-react';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if ((navigator as any).userAgentData?.brands.some((x: any) => x.brand === 'Microsoft Edge') === false) {
  sessionStorage.setItem("tma.js/launch-params", "tgWebAppPlatform=tdesktop&tgWebAppThemeParams=%7B%22accent_text_color"
    + "%22%3A%22%2379e8da%22%2C%22bg_color%22%3A%22%23282e33%22%2C%22button_color%22%3A%22%233fc1b0%22%2C%22button_text_color"
    + "%22%3A%22%23ffffff%22%2C%22destructive_text_color%22%3A%22%23f57474%22%2C%22header_bg_color%22%3A%22%23282e33%22%2C%22"
    + "hint_color%22%3A%22%2382868a%22%2C%22link_color%22%3A%22%234be1c3%22%2C%22secondary_bg_color%22%3A%22%23313b43%22%2C%22"
    + "section_bg_color%22%3A%22%23282e33%22%2C%22section_header_text_color%22%3A%22%234be1c3%22%2C%22subtitle_text_color"
    + "%22%3A%22%2382868a%22%2C%22text_color%22%3A%22%23f5f5f5%22%7D&tgWebAppVersion=7.4&tgWebAppData=query_id"
    + "%3DAAHjG9EFAAAAAOMb0QWA9gcJ%26user%3D%257B%2522id%2522%253A97590243%252C%2522first_name%2522%253A%2522Orlangure"
    + "%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522Orlangure%2522%252C%2522language_code"
    + "%2522%253A%2522ru%2522%252C%2522allows_write_to_pm%2522%253Atrue%257D%26auth_date"
    + "%3D1718980860%26hash%3D29540345a920687caba7a27f656147155612f159cd0a6bace8194be7baa00f99");
}

root.render(
  <React.StrictMode>
    <SDKProvider debug={true}>
      <App />
    </SDKProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
