import { useNavigate } from "react-router-dom";
import { ProductInfo } from "../interfaces/product.interface";
import { CartItem } from "../interfaces/cartItem.interface";
import { CatalogCounter } from "./CatalogCounter";
import { formatNumberWithSpaces } from "../views/Catalog";
import { getAttachementUrl } from "../utils";

interface ProductPreviewProps {
  product: ProductInfo;
  inCart: CartItem | undefined;
  setCart: Function;
  cart: CartItem[];
};

export function ProductPreview(props: ProductPreviewProps) {
  const { product, inCart, setCart, cart } = props;
  const nav = useNavigate();
  const thumbnail: string = product.thumbnail !== undefined
    ? getAttachementUrl(product.thumbnail)
    : 'empty-thumbnail-102x102.png';

  return (
    <div className="menuItem" key={'product_' + product.id}>
      <div className="info">
        <img
          onClick={() => {
            localStorage.setItem("product", JSON.stringify({ ...product, inCart: inCart ? inCart.quantity : 0, }));
            nav(`/product/${product.id}`);
          }}
          src={thumbnail}
          alt="food"
          style={{}}
          className="picture" />
        <div className="name">{product.name}</div>
      </div>
      {inCart
        ? <CatalogCounter product={product} inCart={inCart} setCart={setCart} cart={cart} />
        : (
          <div
            onClick={() => {
              localStorage.setItem("product", JSON.stringify({ ...product, }));
              nav("/product/" + product.id);
            }}
            className="price">
            <span>{formatNumberWithSpaces(Number(product.price))}&nbsp;₽</span>
          </div>
        )}
    </div>
  );
}
